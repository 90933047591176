@import url('https://fonts.googleapis.com/css?family=Teko');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    background: rgba(240, 240, 240, 0.9);
    
  }

.App{
    font-family: Teko;

}