#Code {
    margin-bottom: 20px;
}

#outsideBox {
    padding-top: 200px;
}

.box {
    border: 3px solid #000000; 
    background-color: rgb(230, 230, 230);
}

.boxListCode {
    margin: 10px; 
    background-color: rgb(230, 230, 230);
    border: 3px solid #000000;  
}
a {
    text-decoration: none;
}

a:hover, a:focus {
    text-decoration: underline;
    color: rgb(194, 125, 15);
}
@media screen and (max-width: 769px) {
    .boxList {
        width: 70%; 
    }

    .box {
        display: block;
        margin: auto;
        background-color: rgb(230, 230, 230);
        border-radius: 25px;
        width: 80%;
        color: #000;
        border: 5px solid black; 
    }
}
