#PersonalBlog {
    margin: 20px;
}
.photo {
    width: 24%; 
    border: 2px solid #000;
    margin: 5px; 
}

@media screen and (max-width: 769px) {
    .photo {
        width: 45%; 
        border: 2px solid #000;
        margin: 5px; 
    }
}
