nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #2C3E50;
    padding: 20px 80px;
    box-shadow: 0 5px 15px (0,0,0,0.06);
}

#navbar{
    display: flex;
    align-items: center;
    justify-content: center;
}

#navbar li{
    list-style: none;
    padding: 0 20px;
    position: relative;
}


#navbar li a{
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 600;
    color: RGB(236, 240, 241);
    transition: 0.3s ease-in-out;
}

#navbar li a:hover{
    color: #F39C12;
}

#navbar li a:hover::after,
#navbar li a:active::after{
    content: ""; 
    width: 30%;
    height: 2px;
    background: #F39C12; 
    position: absolute; 
    bottom: -4px; 
    left: 20px; 
}

#mobile{
    display: none;
}
#mobile i{
    color: RGB(236, 240, 241)
}

@media screen and (max-width:769px){
    #navbar{
        display: flex;
        flex-direction: column ;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed; 
        top: 90px; 
        right: -300px; 
        width: 300px;
        height: 100vh; 
        background-color: #2C3E50;
        box-shadow: 0 40px 60px (0,0,0,0.1);
        padding: 40px 0 0 10px;
        z-index: 99999;
        transition: 0.3s ease-in-out; 
    }

    #navbar.active{
        right: 0px; 
    }

    #navbar li{
        margin-bottom: 25px;
        color: RGB(236, 240, 241);
    }

    #mobile{
        display: block;
    }

    #mobile i{
        font-size: 24px;
        cursor: pointer;
    }
}

