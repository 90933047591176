#Home {
  margin-bottom: 20px;
}

.boxH {
  display: block;
  margin: auto;
  background-color: rgb(230, 230, 230);
  border-radius: 25px;
  width: 55%;
  color: #000;
  padding: 10px; 
  border: 3px solid black;
}

.center {
  text-align: center;
}

#contact h2 {
  text-align: center;
  list-style: none;
  padding: 8px;
  position: relative;
  font-size: 2rem;
}

.profileImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 100%;
  width: 25%;
  border: 3px solid #000;
}

#photo {
  padding-top: 10px;
}

@media screen and (max-width: 769px) {
  .profileImg {
    width: 45%;
  }
  .boxH {
    width: 80%;
  }
}

.row {
  display: flex;
  justify-content: space-around;
  align-items: stretch; 
}

.column {
  flex: 1; 
  max-width: 30%; 
  display: flex;
  justify-content: center;
}

.DinsideBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; 
}

.Dicon {
  width: 60px; 
  height: 60px;
  margin-bottom: 10px;
}

.DinsideText {
  text-align: center; 
}
