.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-left: 5%;  
    margin-right: 5%; 
}

.column {
    flex: 1;  
    max-width: 30%;  
    padding: 10px;  
    box-sizing: border-box; 
}

.boxList {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px; 
    background-color: rgb(230, 230, 230);
    border: 3px solid #000000; 
    border-radius: 25px;
    flex: 1;
    max-width: 100%; 
    color: #000;
    box-sizing: border-box;
}

.boxListCode {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    background-color: rgb(230, 0, 0);
    border-radius: 25px;
    flex: 1;
    max-width: 33%; 
    color: #000;
    border: 5px solid black;
    box-sizing: border-box;
}

.DinsideBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Dicon {
    height: 60px;
    width: 60px;
    padding: 10px;
}

.DinsideText {
    font-size: 1.4rem;
    text-align: center;
}

@media screen and (max-width: 769px) {
    .row {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
    }

    .column {
        max-width: 100%; 
        padding: 10px 0;
    }

    .boxList {
        max-width: 100%;
    }
    .boxListCode {
        max-width: 100%;
    }
}
